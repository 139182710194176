import './index.css';
import { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Background from './components/three/background/background';
import About from './views/about/about';
import Work from './views/work/work';
import Overlay from './components/overlay/overlay';
import Links from './views/links/links';
import Loading from './components/loading/loading';
import { ThreejsLoadingContext, IThreejsLoadingContext } from './components/context/loadingContext';

export default function App() {
  const [loading, setLoading] = useState<IThreejsLoadingContext['loading']>({
    message: '',
    current: 0,
    total: 1,
    isLoading: true,
  });
  return (
    <>
      <ThreejsLoadingContext.Provider value={{ loading, setLoading }}>
        <Background />
        <Loading />
        <Overlay />
      </ThreejsLoadingContext.Provider>
      {loading.isLoading ? (
        <></>
      ) : (
        <>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/work" element={<Work />} />
            <Route path="/links" element={<Links />} />
            <Route path="*" element={<Navigate to="/about" replace />} />
          </Routes>
        </>
      )}
    </>
  );
}
