import { useLocation, useNavigate } from 'react-router';
import styles from './bottomPanel.module.scss';

interface Props {
  directories?: Array<string>;
}

export default function BottomPanel(props: Props) {
  const location = useLocation();
  const locationName = location.pathname.replace('/', '');
  const navigate = useNavigate();
  return (
    <div className={styles.panel}>
      <div className={styles.bottomRight} />
      <div className={styles.bottomLeft}></div>
      <div className={styles.topLeft}></div>
      <div className={styles.options}>
        {props.directories?.map((directory) => {
          return (
            <div
              key={directory}
              onClick={() => navigate(`/${directory}`)}
              className={`${styles.option} ${directory === locationName ? styles.activeOption : styles.idleOption}`}
            ></div>
          );
        })}
      </div>
      <div className={styles.optionContentContainer}>
        {props.directories?.map((directory) => {
          return (
            <div
              key={directory}
              onClick={() => (directory === 'home' ? navigate('/') : navigate(`/${directory}`))}
              className={`${styles.optionContent} ${
                directory === locationName ? styles.activeOptionName : styles.idleOptionName
              }`}
            >
              {directory === '' ? 'index' : directory}
            </div>
          );
        })}
      </div>
    </div>
  );
}
