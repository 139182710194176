import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router';
import TypingAnimation from '../typingAnimation';
import styles from './overlay.module.scss';
import Terminal from '../terminal/terminal';
import TopPanel from '../panel/topPanel/topPanel';
import BottomPanel from '../panel/bottomPanel/bottomPanel';
import { ThreejsLoadingContext } from '../context/loadingContext';

export default function Overlay() {
  const { loading } = useContext(ThreejsLoadingContext);
  const [zIndex, setZIndex] = useState<number>(3);
  const location = useLocation();
  const currentDirectory = location.pathname.replace('/', '');
  const validDirectories = ['about', 'work', 'links'];

  useEffect(() => {
    if (!loading.isLoading) setTimeout(() => setZIndex(0), 1000);
  }, [loading.isLoading]);

  return (
    <div className={styles.container} style={{ zIndex }}>
      <TopPanel>
        <div className={styles.directory}>
          {loading.isLoading ? (
            <span>loading...</span>
          ) : (
            <>
              <span>neeto:\</span>
              <TypingAnimation fullText={currentDirectory} timeout={40} />
            </>
          )}
        </div>
        <Terminal drive="neeto" directory={currentDirectory} allDirectories={validDirectories} />
      </TopPanel>
      <BottomPanel directories={validDirectories} />
    </div>
  );
}
