import LinkContainer from '../../components/linkContainer/linkContainer';
import Panel from '../../components/panel/contentPanel/panel';
import styles from './links.module.scss';

export default function Links() {
  return (
    <div className={styles.links}>
      <Panel height={320} width={320}>
        <LinkContainer>
          {[
            <a key={0} href="mailto:hello@neeto.dev">
              hello@neeto.dev
            </a>,
            <a key={1} href="https://github.com/Leonij" target="_blank">
              github
            </a>,
          ]}
        </LinkContainer>
      </Panel>
    </div>
  );
}
