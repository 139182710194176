import styles from './work.module.scss';
import mlinc from '../../images/work/mlinc.jpg';
import { ReactComponent as ReactIcon } from '../../images/icons/react.svg';
import { ReactComponent as TypescriptIcon } from '../../images/icons/typescript.svg';
import { ReactComponent as JavaIcon } from '../../images/icons/java.svg';
import { ReactComponent as SQLIcon } from '../../images/icons/sql.svg';
import Panel from '../../components/panel/contentPanel/panel';

export default function MlincPanel() {
  return (
    <Panel className={styles.panel} width={320} height={320}>
      <div className={styles.name}>
        <span>MLINC</span>
      </div>
      <div className={styles.content}>
        <div className={styles.description}>
          <a href="https://www.mlinc.com.au/" target="blank">
            https://www.mlinc.com.au/
          </a>
          <ul>
            <li>Front end for sports fulfilment company internal and external tools</li>
            <li>Member search for clients</li>
            <li>Real time warehouse job processing details using web sockets</li>
          </ul>
        </div>
        <div className={styles.stack}>
          <ul>
            <li>
              <ReactIcon />
              React
            </li>
            <li>
              <TypescriptIcon />
              Typescript
            </li>
            <li>
              <JavaIcon />
              Java
            </li>
            <li>
              <SQLIcon />
              SQL
            </li>
          </ul>
        </div>
        <img src={mlinc} alt="mlincbg" className={styles.bgImage} />
      </div>
    </Panel>
  );
}
