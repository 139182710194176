import { createContext } from 'react';

export interface IThreejsLoadingContext {
  loading: {
    message: string;
    current: number;
    total: number;
    isLoading: boolean;
  };
  setLoading: (loading: { message: string; current: number; total: number; isLoading: boolean }) => void;
}

const defaultState = {
  loading: { message: '', current: 0, total: 1, isLoading: true },
  setLoading: () => null,
};

export const ThreejsLoadingContext = createContext<IThreejsLoadingContext>(defaultState);
