import Panel from '../../components/panel/contentPanel/panel';
import styles from './about.module.scss';

export default function About() {
  return (
    <div className={styles.about}>
      <Panel className={styles.panel} height={320} width={320}>
        <div className={styles.aboutContent}>
          <p>Hi! I'm Joe "neeto" Leoni.</p>
          <p>I'm a full stack web developer based in Melbourne.</p>
          <p>
            I have experience with several languages, frameworks and database technologies, including Typescript, React,
            Node.js, Three.js, Express, MongoDB, SQL, PHP and more!
          </p>
          <p>Contact me - <a href='mailto:hello@neeto.dev'>hello@neeto.dev</a></p>
        </div>
      </Panel>
    </div>
  );
}
