import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCheckDevice from '../../hooks/useCheckDevice';
import TypingAnimation from '../typingAnimation';
import styles from './terminal.module.scss';

interface Props {
  drive: string;
  directory: string;
  allDirectories: Array<string>;
}

export default function Terminal(props: Props) {
  const [terminalInput, setTerminalInput] = useState<string>('');
  const [terminalError, setTerminalError] = useState<boolean>(false);
  const terminalInputRef = useRef<HTMLDivElement>(null);
  const { isTouchDevice } = useCheckDevice();
  const navigate = useNavigate();

  useEffect(() => {
    if (terminalInputRef.current) {
      terminalInputRef.current.focus();
    }
  }, []);

  const handleKeyUp = () => {
    if (terminalInputRef.current) {
      setTerminalInput(terminalInputRef.current.innerText.toLowerCase());
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (props.allDirectories.includes(terminalInput)) {
        setTerminalError(false);
        navigate(`/${terminalInput}`);
      } else {
        setTerminalError(true);
      }

      if (terminalInput === 'home' || terminalInput === 'cd') {
        setTerminalError(false);
        navigate('/');
      }

      setTerminalInput('');
      if (terminalInputRef.current) {
        terminalInputRef.current.innerText = '';
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.terminal}>
        <span
          ref={terminalInputRef}
          className={styles.terminalInput}
          onBlur={(e) => (isTouchDevice() ? e.target.blur() : e.target.focus())}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          contentEditable
        ></span>
        <div className={styles.terminalError}>
          <TypingAnimation fullText={terminalError ? props.allDirectories.join(' ') : ''} timeout={20} />
        </div>
      </div>
    </div>
  );
}
