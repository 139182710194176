import styles from './topPanel.module.scss';

type Props = {
  children?: React.ReactNode;
};

export default function TopPanel(props: Props) {
  return (
    <div className={styles.panel}>
      <div className={styles.topLeft} />
      <div className={styles.topRight}></div>
      <div className={styles.bottomRight}></div>
      <div className={styles.container}>{props.children}</div>
    </div>
  );
}
