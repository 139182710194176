import React, { useState, useRef } from 'react';
import styles from './panel.module.scss';

interface Props {
  children?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  styles?: object;
  expand?: boolean;
  setOpen?: any;
  className?: string;
}

export default function Panel(props: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [mousePosition, setMousePosition] = useState<any>({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const borderRef = useRef<HTMLDivElement>(null);

  const handleExpand = () => {
    props.setOpen(!open);
    setOpen(!open);
  };

  const handleMouseMove = (e: any) => {
    if (borderRef.current && containerRef.current) {
      const offsetLeft = containerRef.current.offsetLeft;
      const offsetTop = containerRef.current.offsetTop;
      const offsetWidth = containerRef.current.offsetWidth;
      const offsetHeight = containerRef.current.offsetHeight;
      setMousePosition({
        x: e.clientX - Math.round(offsetLeft),
        y: e.clientY - Math.round(offsetTop),
      });
      const transformStyle = {
        x: (mousePosition.x / offsetWidth) * 2 - 1,
        y: (mousePosition.y / offsetHeight) * 2 - 1,
      };
      if (!open) {
        borderRef.current.style.transform = `scale(1.15) rotateX(${-transformStyle.y}deg) rotateY(${
          transformStyle.x
        }deg)`;
      } else {
        borderRef.current.style.transform = `scale(1.1) rotateX(${-transformStyle.y}deg) rotateY(${
          transformStyle.x
        }deg)`;
      }
    }
  };

  const handleMouseLeave = () => {
    if (borderRef.current) {
      borderRef.current.style.transform = `scale(1.1)`;
    }
  };

  return (
    <div
      className={`${styles.panelContainer} ${props.className}`}
      ref={containerRef}
      style={{
        ...props.styles,
        width: props.width,
        height: props.height,
      }}
      onClick={props.expand ? handleExpand : undefined}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.borderCornersContainer} ref={borderRef} style={{ transform: 'scale(1.1)' }}>
        <div className={styles.borderCorners}></div>
      </div>
      <div className={styles.panel}>
        <div className={styles.panelContent}>{props.children}</div>
      </div>
    </div>
  );
}
