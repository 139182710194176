import DigimekaPanel from './digimekaPanel';
import MlincPanel from './mlincPanel';
import styles from './work.module.scss';

export default function Work() {
  return (
    <div className={styles.container}>
      <div className={styles.panelContainer}>
        <DigimekaPanel />
        <MlincPanel />
      </div>
    </div>
  );
}
