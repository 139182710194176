import styles from './work.module.scss';
import digimeka from '../../images/work/digimeka.png';
import { ReactComponent as ReactIcon } from '../../images/icons/react.svg';
import { ReactComponent as NodejsIcon } from '../../images/icons/nodejs.svg';
import { ReactComponent as MongodbIcon } from '../../images/icons/mongodb.svg';
import { ReactComponent as JavascriptIcon } from '../../images/icons/javascript.svg';
import { ReactComponent as PuppeteerIcon } from '../../images/icons/puppeteer.svg';
import Panel from '../../components/panel/contentPanel/panel';

export default function DigimekaPanel() {
  return (
    <Panel className={styles.panel} width={320} height={320}>
      <div className={styles.name}>
        <span>DIGIMEKA</span>
      </div>
      <div className={styles.content}>
        <div className={styles.description}>
          <a href="https://digimeka.neeto.dev/" target="blank">
            https://digimeka.neeto.dev/
          </a>
          <ul>
            <li>Hobby TCG website</li>
            <li>Performantly lazy loading 2000+ dynamic react components</li>
            <li>Dynamic links to share with others using base64 encrypted search parameters</li>
            <li>Database and API created with scraped official data</li>
          </ul>
        </div>
        <div className={styles.stack}>
          <ul>
            <li>
              <ReactIcon />
              React
            </li>
            <li>
              <JavascriptIcon />
              Javascript
            </li>
            <li>
              <NodejsIcon />
              Node.js
            </li>
            <li>
              <MongodbIcon />
              MongoDB
            </li>
            <li>
              <PuppeteerIcon />
              Puppeteer
            </li>
          </ul>
        </div>
        <img src={digimeka} alt="digimekabg" className={styles.bgImage} />
      </div>
    </Panel>
  );
}
