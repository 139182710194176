import { ReactNode, ReactElement, Children, useRef } from 'react';
import styles from './linkContainer.module.scss';

interface Props {
  children: Array<ReactElement<any>>;
}
export default function LinkContainer(props: Props) {
  const linkRef = useRef<HTMLDivElement>(null);
  const borderRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnterLink = (e: any) => {
    if (borderRef.current && linkRef.current) {
      borderRef.current.style.transform = `translate(${e.target.offsetLeft}px, ${e.target.offsetTop}px)`;
      borderRef.current.style.opacity = '1';
      borderRef.current.style.filter = 'blur(0px)';
    }
  };

  const handleMouseLeaveContainer = () => {
    if (borderRef.current) {
      borderRef.current.style.opacity = '0';
      borderRef.current.style.filter = 'blur(5px)';
    }
  };

  const LinkBorder = () => <div className={styles.linkBorder} ref={borderRef}></div>;

  return (
    <div className={styles.container}>
      <div onMouseLeave={handleMouseLeaveContainer} className={styles.links} ref={containerRef}>
        <LinkBorder />
        {Children.map(props.children, (child: ReactNode) => {
          return (
            <div onMouseEnter={handleMouseEnterLink} ref={linkRef} className={styles.link}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}
