import { useEffect, useRef, useContext } from 'react';
import {
  ACESFilmicToneMapping,
  Clock,
  DirectionalLight,
  EquirectangularReflectionMapping,
  HemisphereLight,
  LinearFilter,
  LoadingManager,
  Mesh,
  MeshStandardMaterial,
  OrthographicCamera,
  PlaneBufferGeometry,
  Scene,
  sRGBEncoding,
  WebGLRenderer,
} from 'three';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import styles from './background.module.scss';
import environmentMap from '../textures/1.hdr';
import { ThreejsLoadingContext } from '../../context/loadingContext';

export default function Background() {
  const { setLoading } = useContext(ThreejsLoadingContext);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      // resize
      const updateSize = () => renderer.setSize(window.innerWidth / 16, window.innerHeight / 16);
      window.addEventListener('resize', updateSize);

      // scene
      const scene = new Scene();

      const hemiLight = new HemisphereLight(0xffffff, 0xffffff, 0.6);
      hemiLight.color.setHSL(0.6, 1, 0.6);
      hemiLight.groundColor.setHSL(0.095, 1, 0.75);
      hemiLight.position.set(0, 50, 0);
      scene.add(hemiLight);

      const dirLight = new DirectionalLight(0xffffff, 1);
      dirLight.color.setHSL(0.1, 1, 0.95);
      dirLight.position.set(-1, 1.75, 1);
      dirLight.position.multiplyScalar(30);
      scene.add(dirLight);

      // renderer
      const renderer = new WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true,
      });
      updateSize();
      renderer.physicallyCorrectLights = true;
      renderer.toneMapping = ACESFilmicToneMapping;
      renderer.outputEncoding = sRGBEncoding;

      // camera
      const camera = new OrthographicCamera(-0.5, 0.5, 0.25, -0.25, 0, 5);
      camera.position.z = 1;

      // mesh
      const geometry = new PlaneBufferGeometry(1, 1, 64, 64);
      const material = new MeshStandardMaterial({
        roughness: 0.5,
        metalness: 1.0,
        envMapIntensity: 0.8,
      });

      // loading
      const loadingManager = new LoadingManager();
      loadingManager.onStart = (message, current, total) => setLoading({ message, current, total, isLoading: true });
      loadingManager.onProgress = (message, current, total) =>
        current === total
          ? setLoading({ message, current, total, isLoading: false })
          : setLoading({ message, current, total, isLoading: true });

      new RGBELoader(loadingManager).load(environmentMap, (texture) => {
        texture.mapping = EquirectangularReflectionMapping;
        texture.magFilter = LinearFilter;
        texture.needsUpdate = true;
        material.envMap = texture;
        material.needsUpdate = true;
      });

      const mesh = new Mesh(geometry, material);
      scene.add(mesh);
      mesh.rotation.x = 0.7;

      // animate
      const clock = new Clock();
      const noise = new ImprovedNoise();
      const animate = () => {
        requestAnimationFrame(animate);
        const time = clock.getElapsedTime() * 1;
        const meshPos = mesh.geometry.attributes.position;
        for (let i = 0; i < meshPos.count; i++) {
          meshPos.setZ(i, noise.noise(meshPos.getX(i) * 1.5, meshPos.getY(i) * 1.5, time / 3) * 0.25);
        }
        meshPos.needsUpdate = true;
        geometry.computeVertexNormals();
        renderer.render(scene, camera);
      };
      requestAnimationFrame(animate);
    }
  }, [canvasRef]);

  return <canvas ref={canvasRef} className={styles.canvas} />;
}
