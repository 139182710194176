import { useEffect, useState } from 'react';

interface Props {
  fullText: string;
  timeout: number;
  className?: string;
}

export default function TypingAnimation(props: Props) {
  const [text, setText] = useState<string>('');
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setText('');
    setIndex(0);
  }, [props.fullText]);

  useEffect(() => {
    if (index < props.fullText.length) {
      setTimeout(() => {
        setText(text + props.fullText[index]);
        setIndex(index + 1);
      }, props.timeout);
    }
  }, [index, props.fullText, props.timeout, text]);

  return <span className={props.className}>{text}</span>;
}
