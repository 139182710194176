import { useRef, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './loading.module.scss';
import { ThreejsLoadingContext } from '../context/loadingContext';

export default function Loading() {
  const { loading } = useContext(ThreejsLoadingContext);
  const nodeRef = useRef(null);
  console.log((loading.current / loading.total) * 100);
  return (
    <CSSTransition
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        exit: styles.exit,
        exitActive: styles.exitActive,
      }}
      timeout={1500}
      in={loading.isLoading}
      nodeRef={nodeRef}
      unmountOnExit
    >
      <div ref={nodeRef} className={styles.loading}>
        <div className={styles.bar}>
          <div style={{ width: `${(loading.current / loading.total) * 90}%` }}></div>
        </div>
      </div>
    </CSSTransition>
  );
}
